<template>
  <div class="sk-fold-box" v-if="profile == null">
    <div class="sk-fold">
      <div class="sk-fold-cube"></div>
      <div class="sk-fold-cube"></div>
      <div class="sk-fold-cube"></div>
      <div class="sk-fold-cube"></div>
    </div>
  </div>
  <a-layout v-else>
    <a-layout-sider class="slider-bg" style="background: #1F2254" :collapsedWidth="$isMobile ? '40px' : '80px'"
      :trigger="$isMobile ? null : ''" v-model:collapsed="collapsed" collapsible>
      <div v-if="institutionsName == 1" style="text-align: center;">
        <img v-if="!collapsed" style="width: 100px; margin: 24px 0;" src="../assets/img/td-logo.png" alt="">
        <img v-if="$isMobile && collapsed" style="width: 28px; margin: 12px 0;" src="../assets/img/m-td-logo.png"
          alt="">
        <img v-if="!$isMobile && collapsed" style="width: 40px; margin: 12px 0;" src="../assets/img/m-td-logo.png"
          alt="">
      </div>
      <a-menu class="mobile-menu" style="background: #1F2254" @click="onMenuSelect" theme="dark" mode="inline"
        :selectedKeys="defaultSelectedKeys" :openKeys="defaultOpenKeys">
        <a-menu-item key="/home">
          <component :is="$icons['HomeOutlined']"></component>
          <span>首页</span>
        </a-menu-item>
        <template v-for="menu in menus">
          <a-menu-item v-if="menu.children == null" :key="menu.key">
            <component :is="$icons[menu.icon]"></component>
            <span>{{ menu.text }}</span>
          </a-menu-item>
          <a-sub-menu v-else :key="menu.key">
            <template #title>
              <span>
                <component :is="$icons[menu.icon]"></component>
                <span> {{ menu.text }}</span>
              </span>
            </template>
            <a-menu-item v-for="child in menu.children" :key="child.key">{{ child.text }}</a-menu-item>
          </a-sub-menu>
        </template>
      </a-menu>
    </a-layout-sider>

    <a-layout>
      <a-layout-header style="background: #fff; padding: 0">
        <a-space>
          <component v-if="!$isMobile" class="trigger" @click="() => collapsed = !collapsed"
            :is="collapsed ? $icons['MenuUnfoldOutlined'] : $icons['MenuFoldOutlined']" />
          <a-breadcrumb>
            <a-breadcrumb-item href=""><router-link to="/home">
                <home-outlined style="margin-left: 10px;" />
                首页</router-link></a-breadcrumb-item>
            <a-breadcrumb-item v-if="title !== '首页'">{{ title }}</a-breadcrumb-item>
          </a-breadcrumb>
        </a-space>
        <div class="helpCon">
          <a-popover v-if="!$isMobile" placement="top">
            <template #content>
              <p class="ls-content">操作手册</p>
            </template>
            <a href="https://kxyb4lhe59.feishu.cn/docx/IVuedeLMUo5clyxQ3fMcGyAVntb" target="_blank">
              <question-circle-outlined class="ls-icon" /></a>
          </a-popover>
          <a-dropdown v-if="!$isMobile">
            <div @click.prevent style="position: relative;">
              <BellOutlined style="font-size: 20px; padding-right: 10px; " />
              <span v-if="profile.notReadNumber > 0" class="red-circle"></span>
            </div>
            <template #overlay>
              <div class="msg">
                <div class="msg-header">
                  <span>未读消息</span>
                  <span style="color: #1677ff; cursor: pointer;" @click="readAllMessage">全部已读</span>
                </div>
                <div v-if="msgList.length > 0" class="msg-list">
                  <div v-for="(item, index) in msgList" class="ml-item" @click="toMessageId(item.id)">
                    <div style="display: flex; align-items: center">
                      <p class="msg-title">{{ item.title }}</p>
                      <span class="circle"></span>
                    </div>
                    <p class="msg-summary">{{ item.summary }}</p>
                    <div style="text-align: right; color: #666666">{{ getDate(item.pushTime) }}</div>
                  </div>
                </div>
                <div v-else style="text-align: center; margin: 40px 0;">
                  <img style="width: 200px;" src="../assets/img/no-msg-data.png" alt="">
                  <p style="text-align: center;">暂无未读消息哦~</p>
                </div>
                <div style="text-align: center; margin-top: 10px;">
                  <span style="color: #1677ff; cursor: pointer;" @click="toMessage()">查看全部</span>
                </div>
              </div>
            </template>
          </a-dropdown>
          <div @click="toMessage()" v-else style="position: relative;">
            <BellOutlined style="font-size: 20px; padding-right: 10px; " />
            <span v-if="profile.notReadNumber > 0" class="red-circle"></span>
          </div>
          <a-dropdown>
            <a class="profile" @click.prevent>
              <a-avatar class="avatar">
                <template #icon>
                  <UserOutlined />
                </template>
              </a-avatar>
              <a-badge :color="statusColor" class="badge" />
              <div class="username">{{ username }}</div>
            </a>
            <template #overlay>
              <a-menu @click="handleMenuClick">
                <a-menu-item key="setStatus">
                  <a href="javascript:">
                    设为{{ whetherOnline }}状态
                    <div class="onlinetip">
                      圆点绿色时表示在线，<br />
                      可接受客资自动分配
                    </div>
                  </a>
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item>
                  <recommendation-code />
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item>
                  <!-- <change-password /> -->
                  <span style="display: inline-block; width: 100%;" @click="toCenter">个人中心</span>
                </a-menu-item>
                <a-menu-divider />
                <a-menu-item key="logout">
                  <a href="javascript:">
                    退出登录</a>
                </a-menu-item>

              </a-menu>
            </template>
          </a-dropdown>
        </div>

      </a-layout-header>
      <a-layout-content>
        <div class="content">
          <router-view @msgChange="msgChange" />
        </div>
      </a-layout-content>
    </a-layout>

  </a-layout>
  <div>
    <a-modal v-model:open="alertModal" title="门店信息完善提醒" width="700px" @cancel="alertModal = false">
      <p style="padding: 40px 0; line-height: 24px; font-size: 16px;">
        您还未完善门店信息，无法在留服搜索进行线上获客。<strong>请您上传门店基础信息并至少完善一项内容</strong>，您会在留服搜索平台（网站\APP\小程序）获得一个专属的网上门店，用户有机会直接访问并预约您的服务。
      </p>
      <template #footer>
        <div style="text-align: center;">
          <a-button type="primary" @click="verifyOk">好的，去上传</a-button>
          <a-button style="margin-left: 30px;" @click="alertModal = false">下次再说</a-button>
        </div>
      </template>
    </a-modal>
    <a-modal v-model:open="msgModal" title="未读消息提醒">
      <p style="padding: 30px 0;">您有重要的未读消息，请前往消息中心查看</p>
      <template #footer>
        <div>
          <a-button @click="msgModal = false">下次再说</a-button>
          <a-button type="primary" @click="receive">好的</a-button>
        </div>
      </template>
    </a-modal>
    <a-modal v-model:open="acModal" title="">
      <p style="padding-top: 30px; font-size: 16px; padding-bottom: 8px;">添加到店福利，参加留服搜索双十一预约有礼活动~
      </p>
      <p style="padding-bottom: 30px; font-size: 16px;">
        参与机构可获得抖音、留服搜索平台额外曝光机会，助理老师优先推荐，促成客户到店签约😄
      </p>
      <template #footer>
        <div>
          <a-button @click="acModal = false">下次再说</a-button>
          <a-button type="primary" @click="toStore">完善活动信息</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>
<script>
import config from "../config";
import { clearProfile, getProfile } from "@/utils/session";
import ChangePassword from "@/components/system/ChangePassword";
import RecommendationCode from "@/components/system/RecommendationCode";
import waterMark from '@/utils/waterMark.js';
import dayjs from "dayjs";
export default {
  components: { ChangePassword, RecommendationCode },
  data() {
    return {
      collapsed: false,
      profile: null,
      statusColor: '',
      whetherOnline: '',
      alertModal: false,
      msgList: [],
      msgModal: false,

      acModal: false
    }
  },
  computed: {
    institutionsName() {
      if (this.profile == null) {
        return '...';
      }
      return this.profile.institutions.id;
    },
    online() {
      if (this.profile == null) {
        return '...';
      }
      return this.profile.online;
    },
    username() {
      if (this.profile == null) {
        return '...';
      }
      return this.profile.username;
    },

    defaultSelectedKeys() {
      return [this.$route.path];
    },

    breadcrumbs() {
      const breadcrumbs = this.$route.meta.breadcrumbs;
      if (breadcrumbs == null) {
        return [];
      }
      return [
        ...breadcrumbs,
        this.$route.meta.title
      ];
    },

    title() {
      return this.$route.meta.title;
    },

    defaultOpenKeys() {
      return this.menus
        .filter(menu => menu.defaultOpen || (menu.children && menu.children.some(child => child.key === this.$route.path)))
        .map(menu => menu.key);
    },

    menus() {
      if (this.profile == null) {
        return [];
      }
      let menus = config.menus.map(menu => ({
        key: menu.key,
        defaultOpen: menu.defaultOpen ?? true,
        text: menu.text,
        icon: menu.icon ?? 'setting',
        children: menu.pages
          .map(pageKey => config.pages[pageKey])
          .filter(page => this.profile.everyPermissions(page.permission))
          .map(page => ({
            key: page.key,
            text: page?.title ?? page.key
          }))
      })).filter(({ children }) => children.length > 0);
      let filterMenus;
      if (this.institutionsName !== 1) {
        filterMenus = menus.filter(item => item.text !== '审批中心');
        filterMenus = filterMenus.filter(item => item.text !== '后期服务');
        // filterMenus.map(item=>{
        //   item.children = item.children.filter(i => i.text !== '后期服务');
        // });
      } else {
        filterMenus = menus
      }
      return filterMenus
    }
  },

  beforeMount() {
    if (this.$isMobile) {
      this.collapsed = true
    }
  },

  async mounted() {
    this.applyProfile();
    this.getForceAlert();
  },

  methods: {
    async applyProfile() {
      try {
        this.profile = await getProfile();
        this.online ? this.statusColor = '#87d068' : this.statusColor = '#f50'
        this.online ? this.whetherOnline = '离线' : this.whetherOnline = '在线'
        this.alertModal = this.profile.alert;
        if(this.profile.alert == false && this.profile.benefitNumber == 0 && this.profile.admin == true) {
          this.acModal = true;
        }
        this.notificationList();
      } catch ({ message, status }) {
        if (status !== 401) { // 不是未登录错误
          this.$message.error(message);
        }
      };
    },

    async notificationList() {
      try {
        let res = await this.$http.get('/message/notification/list', {
          page: 1,
          size: 3,
          readStatus: 0
        })
        if (res.code == '200') {
          this.msgList = res.data;
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async readAllMessage() {
      try {
        let res = await this.$http.get('/message/notification/readAllMessage')
        if (res.code == '200') {
          let r = await this.$http.get('/auth/profile');
          this.profile.notReadNumber = r.notReadNumber;
          this.notificationList();
          this.$message.success('操作成功');
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    async msgChange(e) {
      if (e) {
        let r = await this.$http.get('/auth/profile');
        this.profile.notReadNumber = r.notReadNumber;
      }
    },

    async getForceAlert() {
      try {
        let res = await this.$http.get('/message/notification/getForceAlert')
        if (res.code == '200') {
          if (res.data == true) {
            this.msgModal = true;
          }
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },

    receive() {
      this.$router.push({
        path: '/message',
      })
      this.msgModal = false;
    },

    getDate(date) {
      if (date) {
        return dayjs(date).format('YYYY-MM-DD HH:mm')
      } else {
        return ''
      }
    },

    async setOnlineOrOffline() {
      let online = this.whetherOnline == '在线' ? true : false
      try {
        let res = await this.$http.post('/auth/onlineStatus', { online })
        let resTitle = res.whetherOnline ? '在线' : '离线'
        res.whetherOnline ? this.statusColor = '#87d068' : this.statusColor = '#f50'
        res.whetherOnline ? this.whetherOnline = '离线' : this.whetherOnline = '在线'
        this.$message.success(`设置状态${resTitle}成功`);

      } catch ({ message }) {
        this.$message.error(message);
      }
    },
    async onMenuSelect({ key }) {
      await this.$router.push(key);
    },

    async handleMenuClick({ key }) {
      if (key === 'logout') {
        await this.logout();
        waterMark.set('')
      }
      if (key === 'setStatus') {
        this.setOnlineOrOffline()
      }
    },

    async logout() {
      const hideMessage = this.$message.loading('正在退出...', 0);
      try {
        await this.$http.post('/auth/logout');
        this.$message.success('已退出登录');
        clearProfile();
        await this.$router.replace('/login');
      } catch ({ message }) {
        this.$message.error(message);
      }
      hideMessage();
    },
    verifyOk() {
      let permission = '/clientUser/institutionalShop/getList';
      if (this.profile.permissions == undefined) {
        this.alertModal = false;
        this.$router.push({
          path: '/store/storeManage',
        })
      } else {
        if (this.profile.permissions.indexOf(permission) > -1) {
          this.alertModal = false;
          this.$router.push({
            path: '/store/storeManage',
          })
        } else {
          this.$message.error('暂无权限');
        }
      }
    },

    async toMessageId(id) {
      let r = await this.$http.get('/auth/profile');
      this.profile.notReadNumber = r.notReadNumber;
      this.notificationList();
      this.$router.push({
        path: '/message',
        query: {
          id: id
        }
      });
    },

    toMessage() {
      this.$router.push({
        path: '/message',
      })
    },

    toCenter() {
      this.$router.push({
        path: '/personalcenter',
      })
    },

    toStore() {
      this.$router.push({
        path: '/store/storeManage',
      })
      this.acModal = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.ant-layout {
  min-height: 100vh;
  background: #f5f5f5;
}

.ant-layout-header {
  box-shadow: 0 0 12px rgba(#000, .15);

  h1 {
    margin: 0;
    line-height: 1.5;
    position: relative;
    top: -2px;
  }

  .profile {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 18px 0 0;

    .badge {
      position: absolute;
      top: -15px;
      left: 30px;
    }

    .avatar {
      background: #1F94F7;
    }

    .username {
      margin-left: 8px;
      color: #333;
    }

    &:hover {
      background: #f5f5f5;
      cursor: pointer;
    }
  }
}

.content {
  margin: 12px 16px;
}

.breadcrumb {
  margin: 12px 16px;
}

.trigger {
  font-size: 18px;
  height: 64px;
  display: flex;
  align-items: center;
  padding: 0 16px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  background: #f5f5f5;
}

.helpCon {
  display: flex;
  align-items: center;
  float: right;

  a {
    padding-right: 10px;

    .ls-icon {
      margin-left: 4px;
      font-size: 16px;
      color: #333;
    }
  }

}

.onlinetip {
  font-size: 12px;
  color: #8f8f8f;
  line-height: 20px;
}

:deep(.ant-menu-submenu-selected >.ant-menu-submenu-title) {
  background-color: #5051F9 !important;
}

:deep(.ant-menu-submenu>.ant-menu) {
  background-color: #1F2254 !important;
}
</style>
<style lang="scss">
.slider-bg {
  .ant-layout-sider-trigger {
    background: #1F2254;
  }
}

.mobile-menu {
  .ant-menu-item-selected {
    background-color: #1F2254 !important;
    color: #5051F9;
  }

  .ant-menu-inline {
    background: #1F2254 !important;
  }
}

.ant-menu-item-selected {
  background-color: #1F2254 !important;
  color: #5051F9 !important;
}

.red-circle {
  position: absolute;
  top: 18px;
  right: 8px;
  display: inline-block;
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
}

.msg {
  background-color: #ffffff;
  width: 300px;
  padding: 10px 0;
  border-radius: 8px;
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  overflow-y: scroll;


  .msg-header {
    padding: 0 16px 10px 16px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .msg-list {
    padding: 0 10px;
  }

  .ml-item {
    padding: 10px 0;
    border-bottom: 1px solid #eeeeee;
    cursor: pointer;
  }

  .msg-title {
    max-width: 250px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .circle {
    right: 8px;
    display: inline-block;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
    margin-left: 10px;
    flex-shrink: 0;
  }

  .msg-summary {
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    /* 超出几行省略 */
    overflow: hidden;
    padding: 6px 0;
    color: #666666;
  }
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background: none;
  /*可以设置轨道颜色*/
  padding-right: 2px;
}

/*滚动条*/
::-webkit-scrollbar-thumb {
  background: rgba(142, 142, 142, 0.4);
  border-radius: 6px;
}
</style>