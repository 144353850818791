<template>
  <div class="inline">
    <span @click="openDrawer" v-if="disabledValue !== 'false'">
      <a-button v-if="type === 'button'" :type="action.config.color" :disabled="!disabledValue">
        <template #icon>
          <component v-if="action.icon" :is="$icons[action.icon]"></component>
        </template>
        {{ action.text }}</a-button>
      <a-button v-else-if="type === 'link'" :class="{ 'text-danger': action.config.color === 'danger' }"
        :disabled="!disabledValue" type="link">
        <component v-if="action.icon" :is="$icons[action.icon]"></component>
        {{ action.text }}
      </a-button>
    </span>

    <a-drawer :bodyStyle="{ padding: $isMobile ? '8px' : '' }" :title="action.config.title" placement="right"
      :closable="false" :destroy-on-close="true" :width="$isMobile ? 320 : 800" :visible="drawerVisible"
      @close="closeDrawer">
      <div :class="[{ 'fa-info': action.config.info }]">{{ action.config.info ? action.config.info(selectedRows) : '' }}
      </div>
      <a-skeleton v-if="initLoading" active :title="false" :paragraph="{ rows: 4 }" />
      <a-form v-else-if="initDataReady" ref="form" :model="form" :rules="newrules ? newrules : rules" layout="vertical"
        :class="[$isMobile ? 'm-formCon' : 'formCon']">
        <template v-for="control in controls" :key="control.key">
          <a-form-item
            :class="control.type === 'textarea' || control.type === 'step' || control.type === 'editor' || control.type === 'title' ? 'textareaCon' : ''"
            :name="control.key">
            <div class="label-style" slot="label" v-if="!control.config.disabled">
              <div>
                <span v-if="control.required" class="ls-required">*</span>
                <span :class="control.type === 'title' ? 'ic-title' : ''">{{ control.label }}</span>
              </div>
              <a-popover v-if="control.tips" placement="top">
                <template #content>
                  <p class="ls-content">{{ control.tips }}</p>
                </template>
                <question-circle-outlined class="ls-icon" />
              </a-popover>
            </div>
            <!-- 文本 -->
            <template v-if="control.type === 'text'">
              <a-input @blur="control.blur ? blurChange($event, control.blurconfig) : ''"
                v-model:value.trim="form[control.key]" :placeholder="getPlaceholder(control)"
                :disabled="control.disabled" allow-clear />
            </template>
            <!-- 组合框 -->
            <template v-if="control.type === 'compact'">
              <a-form-item-rest>
                <a-input-group compact>
                  <a-input v-model:value.trim="form[control.key]" :placeholder="getPlaceholder(control)"
                    :disabled="control.disabled" allow-clear style="width: 30%" />
                  <a-select :disabled="control.config.disabled" v-model:value="form[control.config.key]"
                    :filter-option="filterSouOption" show-search :placeholder="getPlaceholder(control.config)"
                    allow-clear style="width: 70%">
                    <a-select-option v-for="option in control.config.options" :key="option.value" :text="option.text">{{
      option.text }}
                    </a-select-option>
                  </a-select>
                </a-input-group>
              </a-form-item-rest>
            </template>
            <!-- 进度条 阶段 -->
            <template v-else-if="control.type === 'step'">
              <a-row class="stepCon">
                <a-col @click="getStep(item, control.key, index)" :span="lengthSpan" :class="[{ 'firstFinshStep': index === 0 }, { 'first': index === 0 && clickedIndex != -1 }, { 'stepblue': index <= clickedIndex && clickedIndex != -1 }, { 'last': index === control.config.options.length - 2 },
    { 'ml10': index === control.config.options.length - 1 && control.config.options.length <= 5 },
    { 'lost': index === control.config.options.length - 1 }, { 'stepred': clickedIndex == -1 }, 'defaultcol']"
                  v-for="(item, index) in control.config.options" :key="index">
                  <span>{{ item.content }}</span>
                </a-col>
              </a-row>
            </template>
            <!-- 日期 -->
            <template v-else-if="control.type === 'date'">
              <a-date-picker v-model:value="form[control.key]" :placeholder="getPlaceholder(control)"
                :disabled-date="control.disabledDate ? disabledDate : () => { }" style="width: 100%" :getPopupContainer="triggerNode => {
      return triggerNode.parentNode || document.body;
    }" />
            </template>

            <!-- 日期时间 -->
            <template v-else-if="control.type === 'datetime'">
              <a-date-picker show-time v-model:value="form[control.key]" :placeholder="getPlaceholder(control)"
                :getPopupContainer="triggerNode => {
      return triggerNode.parentNode || document.body;
    }" style="width: 100%" />
            </template>

            <!-- 文本域 -->
            <template v-else-if="control.type === 'textarea'">
              <a-textarea v-model:value="form[control.key]" :placeholder="getPlaceholder(control)"
                style="width: 100%;height: 100px;" />
            </template>
            <!-- 单选框 -->
            <template v-else-if="control.type === 'radio'">
              <a-radio-group v-model:value="form[control.key]" @change="radioChange">
                <a-radio v-for="option in control.config.options" :disabled="option.disabled" :key="option.value"
                  :value="option.value">
                  {{ option.text }}
                </a-radio>
              </a-radio-group>
            </template>
            <!-- 单选框 颜色框 -->
            <template v-else-if="control.type === 'color'">
              <a-radio-group class="colorRadio" v-model:value="form[control.key]">
                <a-radio class="custom-radio" v-for="option in control.config.options" :disabled="option.disabled"
                  :key="option.value" :value="option.value">
                  <span :style="{ backgroundColor: option.value }" class="color-dot"></span>
                </a-radio>
              </a-radio-group>
            </template>
            <!-- 数值 -->
            <template v-else-if="control.type === 'numeric'">

              <!-- 金额 -->
              <template v-if="control.config.format === 'currency'">
                <a-input-number
                  :formatter="value => (value == null || value === '') ? value : `¥ ${value}`.replace(/(\.\d{2})\d*$/, '$1')"
                  :parser="value => value.replace(/¥\s?|(,*)/g, '')" v-model:value="form[control.key]"
                  :disabled="control.disabled" :min="control.config.min"
                  :max="control.config.max && control.config.max(form)" :placeholder="getPlaceholder(control)"
                  style="width: 100%" />
              </template>

              <!-- 百分比 -->
              <template v-else-if="control.config.format === 'percent'">
                <a-input-number :min="0" :max="100"
                  :formatter="value => (value == null || value === '') ? value : `${value}%`"
                  :parser="value => value.replace('%', '')" v-model:value="form[control.key]"
                  :placeholder="getPlaceholder(control)" style="width: 100%" />
              </template>
              <!-- 普通 单位万 -->
              <template v-else-if="control.config.format === 'money'">
                <a-input prefix="￥" suffix="万" v-model:value="form[control.key]" :placeholder="getPlaceholder(control)"
                  style="width: 100%" oninput="value=value.replace(/(\.\d{2})\d*$/, '$1')"
                  onblur="value = value.replace(/(\.\d{2})\d*$/, '$1')"
                  :formatter="value => (value == null || value === '') ? value : `¥ ${value}`.replace(/(\.\d{2})\d*$/, '$1')"
                  :parser="value => value.replace(/(\.\d{2})\d*$/, '$1')" />
              </template>
              <!-- 金额 -->
              <template v-else-if="control.config.format === 'rmb'">
                <a-input-number v-model:value="form[control.key]"
                  :formatter="value => (value == null || value === '') ? value : `${value}`.replace(/(\.\d{2})\d*$/, '$1')">
                  <template #addonAfter>元</template>
                </a-input-number>
              </template>
              <!-- 金额 -->
              <template v-else-if="control.config.format === 'rmby'">
                <a-input-number v-model:value="form[control.key]"
                  :formatter="value => (value == null || value === '') ? value : `${value}`.replace(/(\.\d{2})\d*$/, '$1')">
                  <template #addonAfter>人民币/元</template>
                </a-input-number>
              </template>
              <!-- +-排序 -->
              <template v-else-if="control.config.format === 'sort'">
                <a-input-number class="sortInputNumber" controls :style="{ width: !$isMobile ? '50%' : '100%' }"
                  v-model:value="form[control.key]">
                  <template #addonBefore>
                    <span
                      @click="aInputNumberStep(form[control.key] + 1, { offset: 1, type: 'up' }, control.key)">+</span>
                  </template>
                  <template #addonAfter>
                    <span
                      @click="aInputNumberStep(form[control.key] - 1, { offset: 1, type: 'down' }, control.key)">-</span>
                  </template>
                </a-input-number>
              </template>
              <!-- 普通 -->
              <template v-else>
                <a-input-number v-model:value="form[control.key]" :placeholder="getPlaceholder(control)"
                  style="width: 100%" />
              </template>
            </template>

            <!-- 下拉选择 -->
            <template v-else-if="control.type === 'select' && !control.config.disabled">
              <a-select :style="{ width: control.config.width + 'px' }" v-model:value="form[control.key]"
                :filter-option="filterSouOption" show-search :placeholder="getPlaceholder(control)"
                :disabled="control.isDisabled ? control.isDisabled(selectedRows, form[control.key]) : false || control.config.disabled"
                allow-clear style="width: 100%">
                <a-select-option v-for="option in control.config.options" :key="option.value" :text="option.text">{{
      option.text }}
                </a-select-option>
              </a-select>
            </template>
            <!-- 下拉多选选择 -->
            <template v-else-if="control.type === 'mSelect'">
              <a-select :style="{ width: control.config.width + 'px' }" v-model:value="form[control.key]"
                :placeholder="getPlaceholder(control)" mode="multiple"
                :allow-clear="control.allowClear == 'false' ? false : true" style="width: 100%" show-search
                :filter-option="filterOption" @change="getSelect($event, control)">
                <a-select-option v-for="option in control.config.options" :key="option.id" :name="option.name"
                  :disabled="option.disabled">{{
      option.name }}
                </a-select-option>
              </a-select>
            </template>

            <!-- 下拉搜索选择 -->
            <template v-else-if="control.type === 'souSelect'">
              <a-select class="page-btm" :filter-option="filterSouOption" show-search
                :style="{ width: control.width + 'px' }" v-model:value="form[control.key]" placeholder="全部" allow-clear
                @change="getSouSelect($event, control, control.config.options)">
                <a-select-option v-for="option in control.config.options" :key="option.id" :text="option.name">
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </template>

            <!-- 远程搜索选择 -->
            <template v-else-if="control.type === 'remoteSelect'">
              <a-select class="ae-select" v-model:value="form[control.key]" show-search
                :placeholder="getPlaceholder(control)" allow-clear :default-active-first-option="false"
                :loading="loading" @search="handleSearch($event, control)">
                <a-select-option @click="changeSelect($event, option.id, control)" v-for="option in optionList"
                  :key="option.id" :value="option.name" :name="option.name">
                  {{ option.name }}
                </a-select-option>
              </a-select>
            </template>
            <!-- switch -->
            <template v-else-if="control.type === 'switch'">
              <a-switch v-model:checked="form[control.key]" default-checked />
            </template>

            <!-- 文件 -->
            <template v-else-if="control.type === 'file'">
              <a-upload :accept="control.config.accept.map(ext => `.${ext}`).join(',')"
                :action="control.config.uploadUrl"
                :list-type="control.config.format === 'image' ? 'picture-card' : 'text'"
                :file-list="fileUploadMap[control.key].fileList" :before-upload="file => onBeforeUpload(control, file)"
                @preview="handleUploadPreview($event, control)" @change="info => handleUploadChange(control, info)">
                <template v-if="fileUploadMap[control.key].fileList.length < 1">
                  <div v-if="control.config.format === 'image'">
                    <plus-outlined />
                    <div class="ant-upload-text">上传</div>
                  </div>
                  <div v-else-if="control.config.format === 'compress'">
                    <a-button>
                      <upload-outlined />
                      选择压缩文件
                    </a-button>
                  </div>
                  <div v-else-if="control.config.format === 'video'">
                    <a-button>
                      <upload-outlined />
                      选择视频文件
                    </a-button>
                  </div>
                </template>
                <template v-else>
                  <div
                    v-if="control.config.format === 'image' && (fileUploadMap[control.key].fileList.length < control.config.length)">
                    <plus-outlined />
                    <div class="ant-upload-text">上传</div>
                  </div>
                  <div v-else-if="control.config.format === 'compress'">
                    <a-button>
                      <upload-outlined />
                      选择压缩文件
                    </a-button>
                  </div>
                  <div v-else-if="control.config.format === 'video'">
                    <a-button>
                      <upload-outlined />
                      选择视频文件
                    </a-button>
                  </div>
                </template>
              </a-upload>
              <a-modal title="文件预览" :visible="fileUploadMap[control.key].previewVisible"
                @cancel="fileUploadMap[control.key].previewVisible = false" :footer="null">
                <template v-if="control.config.format === 'image'">
                  <img style="width: 100%" :src="fileUploadMap[control.key].previewUrl" alt="预览图" />
                </template>
                <template v-if="control.config.format === 'video'">
                  <video :src="fileUploadMap[control.key].previewUrl" controls style="width: 100%" />
                </template>
              </a-modal>
              <span v-if="control.config.imageSize">推荐上传尺寸:{{ control.config.imageSize }}</span>
            </template>

            <!-- 树状下拉选择 -->
            <template v-else-if="control.type === 'treeSelect'">
              <a-tree-select show-search tree-node-filter-prop="title" v-model:value="form[control.key]"
                style="width: 100%" allow-clear :tree-data="getTreeData(control)" tree-default-expand-all
                :placeholder="getPlaceholder(control)">
              </a-tree-select>
            </template>

            <!-- 日期范围 -->
            <template v-else-if="control.type === 'dateRange'">
              <a-range-picker v-model:value="form[control.key]" style="width: 100%" :show-time="control.config.showTime"
                :placeholder="[getPlaceholder(control), getPlaceholder(control)]" />
            </template>

            <!-- 复选框 -->
            <template v-else-if="control.type === 'checkbox'">
              <a-checkbox-group v-model:value="form[control.key]">
                <template v-for="option in control.config.options">
                  <template v-if="!option.disabled || form[control.key].find(v => v === option.value)">
                    <a-checkbox :value="option.value">{{ option.text }}</a-checkbox>
                    <br v-if="control.config.layout === 'vertical'" />
                  </template>
                </template>
              </a-checkbox-group>
            </template>

            <!-- 树状 -->
            <template v-else-if="control.type === 'tree'">
              <a-tree :selectable="false" v-model:checkedKeys="form[control.key]" checkable blockNode
                :tree-data="control.config.options">
                <!-- <template #icon>
                <component v-if="icon" :is="$icons[icon]"></component>
              </template> -->
              </a-tree>
            </template>
            <!-- 级联选择 -->
            <template v-else-if="control.type === 'cascader'">
              <a-cascader v-model:value="form[control.key]" change-on-select :show-search="{ filter }"
                :options="control.config.options" placeholder="全部" />
            </template>
            <!-- 富文本 -->
            <template v-else-if="control.type === 'editor'">
              <div style="width: 100%;">
                <vue3-tinymce style="width: 100%;" v-model="form[control.key]" :setting="setting" />
              </div>
            </template>
            <!-- 评分 -->
            <template v-else-if="control.type === 'rate'">
              <div>
                <a-rate v-model:value="form[control.key]" :allow-half="control.allowHalf" />
              </div>
            </template>

            <!-- 数字范围 -->
            <template v-else-if="control.type === 'numberRange'">
              <div style="display: flex; align-items: center;">
                <a-input-number class="page-btm" style="width: 100px" v-model:value="form[control.start]"
                  placeholder="最少" />
                <span style="margin: 0 10px">-</span>
                <a-input-number class="page-btm" style="width: 100px" v-model:value="form[control.end]"
                  placeholder="最多" />
                <span style="margin-left: 10px;">{{ control.unit }}</span>
              </div>
            </template>

            <!-- 验证码 -->
            <template v-else-if="control.type === 'code'">
              <div style="display: flex; align-items: center;">
                <a-input v-model:value="form[control.key]" :placeholder="getPlaceholder(control)" />
                <span v-if="!validate" @click="getCountDown(control.config)" class="pc-re-code">获取验证码</span>
                <span v-else class="pc-re-code-num">{{ countodwn }}</span>
              </div>
            </template>

          </a-form-item>
        </template>
        <a-form-item class="btn_submit">
          <a-button type="primary" @click="submit" :loading="submitLoading">{{ action.config.submitText }}</a-button>
        </a-form-item>
      </a-form>
    </a-drawer>
    <a-modal v-model:open="openModal" title="提示" @ok="handleOk">
      <p class="am-content">{{ modalValue }}<span v-if="blurChangeDetail.status == 'LOSS'">。当前客户已流入公海，要捞起吗？</span></p>
      <template #footer>
        <a-button @click="openModal = false">取消</a-button>
        <a-button v-if="blurChangeDetail.status == 'LOSS'" type="primary" @click="PickUp()"
          :loading="submitLoading">捞起客资</a-button>
        <a-button v-else type="primary" @click="openModal = false" :loading="submitLoading">确认</a-button>

      </template>
    </a-modal>
    <a-modal v-model:open="openWarnModal" title="警告">
      <p style="padding: 20px 0;">已提交过相同金额的订单，确定还要提交吗</p>
      <template #footer>
        <a-button @click="closeWarn">取消</a-button>
        <a-button type="primary" @click="modalSubmit" :loading="submitLoading">确定，不是重复提交</a-button>
      </template>
    </a-modal>
    <a-modal v-model:open="openVerifyModal" title="提示">
      <p class="am-content">{{ action.config.verify.text }}</p>
      <template #footer>
        <a-button @click="onlySubmit">仅保存</a-button>
        <a-button type="primary" @click="verifyOk" :loading="submitLoading">移入公海</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { capitalize, formatBytes } from "@/utils/core";
import { get } from "@/utils/http";
import tree from "@/utils/tree";
import config from "@/config";
import { getProfile } from "@/utils/session";
import dayjs from "dayjs";
import { debounce } from "lodash";
import { toRaw } from '@vue/reactivity'
import Vue3Tinymce from '@jsdawn/vue3-tinymce';
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  name: "ConfirmAction",
  props: ['action', 'type', 'selectedRows', 'disabledValue'],
  components: {
    Vue3Tinymce
  },
  data() {
    return {
      openWarnModal: false,
      openVerifyModal: false,
      lengthSpan: 6,
      clickedIndex: 0,
      drawerVisible: false,
      submitLoading: false,
      form: this.initForm(),
      initData: {},
      initLoading: false,
      initDataReady: false,
      fileUploadMap: this.initFileUploadMap(),
      openModal: false,
      modalValue: '',
      optionList: [],
      loading: false,
      isSpecialInstitutions: null,
      newrules: null,
      //富文本设置
      setting: {
        menubar: false,
        toolbar:
          'bold italic underline h1 h2 blockquote codesample numlist bullist link image | removeformat fullscreen',
        plugins: 'codesample link image table lists fullscreen',
        toolbar_mode: 'sliding',
        nonbreaking_force_tab: true,
        link_title: false,
        default_link_target: '_blank',
        // 自定义 图片上传模式
        custom_images_upload: true,
        images_upload_url: '',
        custom_images_upload_callback: (res) => res.url,
        custom_images_upload_header: { 'X-Token': 'xxxx' },
        // custom_images_upload_param: { id: 'xxxx01', age: 18 },
        // 设置中文语言
        language: 'zh-Hans',
        language_url: '/tinymce/langs/zh-Hans.js',
        content_style: 'body{font-size: 14px}',
      },

      countodwn: 59,
      validate: false,
      countIn: null,
      blurChangeDetail: {},
    }
  },

  created() {
    this.watchMapKeys.forEach(key => {
      this.$watch('form.' + key, (newValue, oldValue) => {
        this.watchMap[key].forEach(({ watch, control }) => {
          const arrayData = control.watch.sourceKey
          const objectData = this.initData
          const sourceValue = {};
          const selectedRows = this.selectedRows
          for (let i = 0; i < arrayData.length; i++) {
            const key = arrayData[i];
            sourceValue[key] = objectData[key];
          }
          const args = {
            form: this.form,
            newValue,
            oldValue,
            sourceValue,
            selectedRows
          };
          //this.form[control.key] = ' '
          if (typeof watch.disabled == 'function') {
            control.config.disabled = !JSON.parse(watch.disabled(args));
            control.required = JSON.parse(watch.disabled(args));
            //this.form[control.key] = ''
            if (control.required) {
              const array = this.rules[control.key]
              let hasRequiredTrue = false;
              for (const item of array) {
                if (item.required === true) {
                  hasRequiredTrue = true;
                  break;
                }
              }
              if (!hasRequiredTrue) {
                this.rules[control.key].unshift(
                  { required: true, message: `请${this.getPlaceholder(control)}` })
              }
              this.newrules = this.rules
            } else {
              this.form[control.key] = ''
              //console.log(this.$refs['form']);
              this.rules[control.key] = this.rules[control.key].filter((item) => {
                return item.required !== true
              })
              this.newrules = null
            }
            // console.log(this.rules, 'this.rules');
          }
        });
      }, { deep: true, immediate: true });
    });

  },

  computed: {
    /**
     * 验证规则
     */
    rules() {
      return this.controls.reduce((prev, control) => {
        const controlRules = prev[control.key] = prev[control.key] ?? [];
        if (control.required) {
          controlRules.push({
            required: true,
            message: `请${this.getPlaceholder(control)}`
          });
        }
        if (control.config.rules != null) {
          const configRules = typeof control.config.rules === 'string' ? [control.config.rules] : control.config.rules;
          configRules.forEach(ruleName => {
            if (ruleName === 'phoneNumber') {
              controlRules.push({
                pattern: /^1\d{10}$/,
                message: '请输入正确的手机号'
              });
            } else if (ruleName === 'email') {
              controlRules.push({
                type: 'email',
                message: '请输入正确的邮箱地址'
              });
            } else if (ruleName === 'wechat') {
              controlRules.push({
                pattern: /^[a-zA-Z-_0-9]([-_a-zA-Z0-9]{5,19})+$/,
                message: '请输入正确的微信号'
              });
            } else if (ruleName === 'url') {
              controlRules.push({
                pattern: /^([hH][tT]{2}[pP]:\/\/|[hH][tT]{2}[pP][sS]:\/\/)(([A-Za-z0-9-~]+).)+([A-Za-z0-9-~\/])+$/,
                message: '请输入以 http:// 或 https:// 开头的正确网址'
              });
              controlRules.push({ max: 250, message: '链接地址不能超过500字符' });
            } else {
              controlRules.push(ruleName);
            }
          });
        }
        return prev;
      }, {});
    },
    /**
     * 表单项
     */
    controls() {
      const controls = this.action.config.controls ?? [];
      let newControls = controls.map(control => {
        if (control.type == 'date') {
          if (control.today) {
            let td = dayjs().format('YYYY-MM-DD');
            this.form[control.key] = dayjs(td);
          }
        }

        if (control.defaultValue) {
          this.form[control.key] = control.defaultValue;
        }
        return {
          ...control,
          config: {
            rules: [],
            ...control.config,
            hidden: this.getHideData(this.initData, control),
            ...(control.type === 'select' ? {
              options: typeof control.config.options === 'string' ? (this.initData[control.config.options] ?? []).map(item => ({
                text: item[control.config.textKey ?? 'name'],
                value: item[control.config.valueKey ?? 'id']
              })) : control.config.options
            } : {}),
            ...(control.type === 'compact' ? {
              options: this.getCompactData(this.initData, control.config)
            } : {}),
            ...(control.type === 'radio' ? {
              options: this.getRadioData(this.initData, control)
            } : {}),
            ...(control.type === 'mSelect' ? {
              // options: this.initData[control.config.options],
              options: this.getMSelectData(this.initData, control)
            } : {}),
            ...(control.type === 'souSelect' ? {
              options: typeof control.config.options === 'string' ? (this.initData[control.config.options] ?? []).map(item => ({
                name: item[control.config.textKey ?? 'name'],
                id: item[control.config.valueKey ?? 'id']
              })) : control.config.options
            } : {}),
            ...(control.type === 'step' ? {
              options: this.getStepData(this.initData, control)
            } : {}),
            ...(control.type === 'file' ? {
              accept: control.config.accept ?? ({
                image: ['png', 'jpg', 'gif'],
                compress: ['zip', 'rar', '7z']
              }[control.config.format])
            } : {}),
            ...(control.type === 'checkbox' ? {
              options: typeof control.config.options === 'string' ? (this.initData[control.config.options] ?? []).map(item => ({
                text: item[control.config.textKey ?? 'name'],
                value: item[control.config.valueKey ?? 'id'],
                disabled: item['locked']
              })) : control.config.options
            } : {}),
            ...(control.type === 'tree' ? {
              options: typeof control.config.options === 'function' ? control.config.options({ config, isSpecialInstitutions: this.isSpecialInstitutions }) : control.config.options
            } : {}),
            ...(control.type === 'treeSelect' ? {
              options: typeof control.config.options === 'string'
                ? tree(this.initData[control.config.options] ?? [], {
                  transform(node) {
                    const value = node[control.config.valueKey ?? 'id'];
                    return {
                      title: node[control.config.textKey ?? 'name'],
                      value,
                      key: value
                    };
                  }
                }) : control.config.options
            } : {}),
          }
        }

      });
      const filteredData = newControls.filter(item => item.config.hidden === false);
      return filteredData;
    },

    watchMapKeys() {
      return Object.keys(this.watchMap);
    },

    watchMap() {
      return this.controls
        .filter(control => control.watch != null)
        .reduce((map, control) => {
          (map[control.watch.target] = map[control.watch.target] ?? []).push({ control, watch: control.watch });
          return map;
        }, {});
    },

    /**
     * 是否禁用
     */
    disabled() {
      if (this.action.disabled == null) {
        return false;
      }

      if (typeof this.action.disabled === 'function') {
        return this.action.disabled({
          selectedRows: this.selectedRows
        });
      }

      return this.action.disabled;
    }
  },
  mounted() {

    // 客户列表跳转添加注入
    if (this.action.text == '添加客户' && this.$route.query.type == 'add') {
      let reg = RegExp(/^(0065|\+65)?[89]\d{7}$|^((\+|00)86)?1[3456789]\d{9}$/);
      let isPhone = reg.test(this.$route.query.wp);
      setTimeout(() => {
        this.openDrawer();
        this.controls.map((item, index) => {
          if (isPhone) {
            if (item.key == 'phoneNumber') {
              this.form[item.key] = this.$route.query.wp
            }
          } else {
            if (item.key == 'wechat') {
              this.form[item.key] = this.$route.query.wp;
            }
          }
        });
      }, 0)
    };

    // 富文本编辑上传接口
    this.action.config.controls.map((item) => {
      if (item.type === 'editor') {
        this.setting.images_upload_url = item.config.uploadUrl
      };
      if (item.defaultValue) {
        this.form[item.key] = item.defaultValue;
      }
    });
  },
  destroyed() {
    this.countodwn = 59;
    this.validate = false;
    clearInterval(this.countIn);
  },
  methods: {
    async getCountDown(config) {
      if (!this.form[config.phone]) {
        this.$message.error('请输入正确的手机号');
        return
      }
      try {
        let res = await this.$http.post(config.url, {
          phoneNumber: this.form[config.phone]
        })
        if (res.code == '200') {
          this.validate = true;
          this.countIn = setInterval(() => {
            if (this.countodwn > 1) {
              this.countodwn--
            } else {
              clearInterval(this.countIn);
              this.countodwn = 59;
              this.validate = false
            }
          }, 1000)
        }
      } catch ({ message }) {
        this.$message.error(message);
      }
    },
    changeSelect(e, value, control) {
      let { formkey } = control.searchConfig
      this.form[formkey] = value
    },
    handleSearch: debounce(async function (val, info) {
      let { submitUrl, key } = info.searchConfig
      if (val) {
        let data = []
        this.loading = true;
        let res = await get(submitUrl, { [key]: val });
        if (res.code == 200) {
          let result = res.list
          result.forEach(r => {
            data.push({
              id: r.id,
              name: r.name,
            });
          });
        }
        setTimeout(() => {
          this.optionList = data
          this.loading = false;
        }, 1000);
      }

    }, 300),
    getStep(item, key, index) {
      this.form[key] = item.id
      this.clickedIndex = index;
      if (item.content == '商机流失') {
        this.clickedIndex = -1
      }
    },
    aInputNumberStep(value, status, KEY) {
      if (status.type == 'down') {
        isNaN(value) ? this.form[KEY] = - 1 : this.form[KEY] = value
      } else {
        isNaN(value) ? this.form[KEY] = 1 : this.form[KEY] = value
      }
    },
    async blurChange(e, info) {
      let { submitUrl, key, needId, title, blurMessage } = info
      let value = e.target._value;
      try {
        let res = await this.$http.get(submitUrl, { [key]: value, id: needId ? this.selectedRows.map(({ id }) => id) : null });
        if (res.code == '200') {
          this.openModal = true;
          let message = ''
          if (typeof blurMessage === 'function') {
            message = blurMessage(res);
          }
          this.modalValue = message;
          this.blurChangeDetail = res;
        }
      } catch ({ message }) {
        //this.$message.error(message);
      }
    },

    async PickUp() {
      try {
        let res = await this.$http.post('/institutions/customer/callingUpTheCustomer',{
          id: this.blurChangeDetail.institutionsCustomerId
        });
        if (res.code == '200') {
          this.$message.success('捞起成功');
          this.openModal = false;
          this.closeDrawer();
          this.$emit('change');
        }
      } catch ({ message }) { 

      }
    },

    disabledDate(current) {
      return current && current > dayjs().endOf('day');
    },
    filterSouOption(input, option) {
      return option.text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    },
    getSelect(value, control) {
      if (control.limitNum && value.length > control.limitNum) {
        value.pop();
        this.$message.error(`最多只能选择${control.limitNum}个`);
      }
    },
    filterOption(input, option) {
      return option.name.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
    },
    filter(inputValue, path) {
      return path.some(option => option.label.toLowerCase().indexOf(inputValue.trim().toLowerCase()) > -1);
    },
    initForm() {
      return this.action.config.controls.reduce((prev, control) => {
        if (control.type === 'dateRange') {
          const start = this.initData?.source?.[control.config.startKey] ?? control.config?.initialValue?.[0];
          const end = this.initData?.source?.[control.config.endKey] ?? control.config?.initialValue?.[1];
          prev[control.config.startKey] = start;
          prev[control.config.endKey] = end;
          prev[control.key] = [start != null ? dayjs(start) : null, end != null ? dayjs(end) : null];
        } else if (control.type === 'compact') {
          prev[control.key] = this.initData?.source?.[control.key] ?? control.config?.initialValue;
          prev[control.config.key] = this.initData?.source?.[control.config.key] ?? control.config.config?.initialValue;
        } else if (control.type === 'step') {
          prev[control.key] = this.initData?.source?.[control.key] ?? this.initData?.[control.config?.options]?.[0].id;
          const idToFind = prev[control.key];
          const foundIndex = this.initData?.[control.config.options].findIndex(item => item.id === idToFind);
          if (foundIndex !== -1) {
            this.clickedIndex = foundIndex
          }
          const foundItem = this.initData?.[control.config.options].find(item => item.id === idToFind);
          if (foundItem) {
            const content = foundItem.content;
            if (content == '商机流失') {
              this.clickedIndex = -1
            }
          }
          this.lengthSpan = 24 / this.initData?.[control.config.options].length
        } else if (control.type === 'numberRange') {
          prev[control.start] = this.initData?.source?.[control.start];
          prev[control.end] = this.initData?.source?.[control.end];
        } else if (control.type === 'mSelect') {
          prev[control.key] = typeof (this.initData?.source?.[control.key]) === 'string' ? this.initData?.source?.[control.key].split(',') : this.initData?.source?.[control.key]
        } else {
          prev[control.key] = this.initData?.source?.[control.key] ?? control.config?.initialValue;
          if (control.type === 'switch') {
            prev[control.key] = prev[control.key] ?? false;
          } else if (control.type === 'date' || control.type === 'datetime') {
            if (typeof prev[control.key] === 'string') {
              prev[control.key] = dayjs(prev[control.key]);
            }
          }
        }
        return prev;
      }, {});
    },

    initFileUploadMap() {
      return this.action.config.controls.filter(({ type }) => type === 'file').reduce((prev, control) => {
        const path = this.initData?.source?.[control.key]?.split(',');
        let fileList = [];
        if (path) {
          path.map(item => {
            fileList.push(
              {
                name: item.split('/').pop(),
                uid: item,
                status: 'done',
                url: item,
                response: {
                  url: item
                }
              }
            )
          })
        };
        prev[control.key] = {
          previewUrl: null,
          previewVisible: false,
          fileList: fileList
        };
        return prev;
        // const path = this.initData?.source?.[control.key];
        // prev[control.key] = {
        //   previewUrl: null,
        //   previewVisible: false,
        //   fileList: path != null ? [{
        //     name: path.split('/').pop(),
        //     uid: path,
        //     status: 'done',
        //     url: path
        //   }] : []
        // };
        // return prev;
      }, {});
    },

    /**
     * 处理文件上传预览
     */
    async handleUploadPreview(file, control) {
      if (control.config.format === 'compress') {
        return;
      }
      if (!file.url && !file.preview) {
        file.preview = URL.createObjectURL(file.originFileObj);
      }

      this.fileUploadMap[control.key].previewUrl = file.url || file.preview;
      this.fileUploadMap[control.key].previewVisible = true;
    },

    /**
     * 处理文件上传状态变更
     */
    handleUploadChange(control, info) {
      if (control.config.length) {
        this.fileUploadMap[control.key].fileList = info.fileList;
        const { status, response } = info.file;
        if (status === 'done') {
          if (info.fileList.length <= control.config.length) {
            let imgArr = [];
            info.fileList.map(item => {
              imgArr.push(item.response.url)
            })
            this.form[control.key] = imgArr;
          } else {
            this.$message.error('超出上传数量')
          }

        } else if (status === 'error') {

          this.$message.error(typeof response === 'string' ? response : response.message);
        }
        if (status === 'removed' && info.fileList.length != 0) {
          let imgArr = [];
          info.fileList.map(item => {
            imgArr.push(item.response.url)
          })
          this.form[control.key] = imgArr;
        }
        if (status === 'removed' && info.fileList.length === 0) {
          this.form[control.key] = null;
        }

      } else {
        this.fileUploadMap[control.key].fileList = info.fileList;
        const { status, response } = info.file;
        if (status === 'done') {
          this.form[control.key] = response.url;
        } else if (status === 'error') {
          this.$message.error(typeof response === 'string' ? response : response.message);
        }
        if (status === 'removed' && info.fileList.length === 0) {
          this.form[control.key] = null;
        }
      }
    },

    /**
     * 限制文件上传格式与大小
     */
    onBeforeUpload(control, file) {
      const ext = file.name.split('.').pop();
      if (!control.config.accept.includes(ext)) {
        file.status = 'error';
        file.response = '文件格式有误';
        this.$message.error('文件格式有误')
        return false;
      }

      if (file.size > control.config.maxSize) {
        file.status = 'error';
        file.response = '文件大小不能超过 ' + formatBytes(control.config.maxSize);
        this.$message.error('文件大小不能超过 ' + formatBytes(control.config.maxSize))
        return false;
      }

      return true;
    },

    //--------------------------------------------
    openDrawer() {
      if (this.action.isSelected && this.selectedRows.length == 0) {
        this.$message.error('请勾选要编辑的内容');
        return
      }
      getProfile()
        .then((result) => {
          this.isSpecialInstitutions = result.institutions.id;
          if (!result.everyPermissions(this.action.permission)) {
            this.$message.error("没有权限访问");
            return;
          }

          this.action.config.controls.map((control) => {
            if (control.authRequired) {
              if (control.authRequired.institution === this.isSpecialInstitutions) {
                control.required = control.authRequired.type
              }

            }
          })
          this.loadInitData();
          this.drawerVisible = true;
        })
    },

    closeDrawer() {
      this.drawerVisible = false;
      this.fileUploadMap = this.initFileUploadMap();
      this.$refs.form.resetFields();
      this.clickedIndex = 0;

      this.controls.filter(control => {
        if (control.type === "mSelect") {
          this.form[control.key] = [];
        };
        this.form[control.start] = '';
        this.form[control.end] = '';
      });
      this.countodwn = 59;
      this.validate = false;
      clearInterval(this.countIn);
    },

    async loadInitData() {
      if (this.action.config.initUrl == null) {
        this.initDataReady = true;
        return;
      }

      let rows = this.selectedRows;
      if(this.selectedRows[0] === undefined) {
        rows = []
      }
      this.initLoading = true;
      try {
        this.initData = await get(this.action.config.initUrl, {
          id: rows.map(({ id }) => id)
        });
        this.initDataReady = true;
      } catch (e) {
        this.$message.error(e.message);
      }
      this.initLoading = false;

      if (this.action.text == '添加客户' && this.$route.query.type == 'add') {
      } else {
        this.form = this.initForm();
      }
      this.fileUploadMap = this.initFileUploadMap();
    },

    async submit() {
      try {
        await this.$refs.form.validate();
      } catch (e) { // 未通过表单验证
        return;
      }
      if (this.initData.orderAmount && this.initData.orderAmount != 0) {
        if (this.initData.orderAmount == this.getForm().orderAmount) {
          this.openWarnModal = true;
          return
        }
      }
      if (this.action.config.verify) {
        if (this.action.config.verify.condition(this.getForm())) {
          this.openVerifyModal = true;
          return
        }
      }
      this.submitLoading = true;
      try {
        const response = await this.$http.post(this.action.config.submitUrl, this.selectedRows.length > 0 ? {
          ...this.getForm(),
          id: this.selectedRows.map(({ id }) => id)
        } : this.getForm());
        if (typeof this.action.config.submitSuccessMessage === 'function') {
          let submitSuccessMessage = this.action.config.submitSuccessMessage;
          submitSuccessMessage = submitSuccessMessage({
            response
          });
          this.$message.success(submitSuccessMessage ?? (this.text + '成功'));
        } else {
          this.$message.success(this.action.config.submitSuccessMessage ?? '操作成功');
        }

        this.closeDrawer();
        this.$emit('change');
        this.countodwn = 59;
        this.validate = false;
        clearInterval(this.countIn);
      } catch ({ message }) {
        let type = message.slice(0, 6);
        if (type == 'type=1') {
          this.modalValue = message.slice(7);
          this.openModal = true;
        } else {
          this.$message.error(message);
        }

      }
      this.submitLoading = false;
    },

    async verifyOk() {
      this.submitLoading = true;
      try {
        const response = await this.$http.post(this.action.config.verify.submitUrl, this.selectedRows.length > 0 ? {
          ...this.getForm(),
          id: this.selectedRows.map(({ id }) => id)
        } : this.getForm());
        if (this.action.config.verify.needId) {
          this.secondVerify(response.id)
        } else {
          this.secondVerify()
        }

      } catch ({ message }) {
        this.$message.error(message);
      }
      this.submitLoading = false;
    },

    async secondVerify(id) {
      try {
        const response = await this.$http.post(this.action.config.verify.secondUrl, {
          id: id ? id : this.selectedRows.map(({ id }) => id)
        });
        this.$message.success(`操作成功`);
        this.openVerifyModal = false;
        this.closeDrawer();
        this.$emit('change');

      } catch ({ message }) {
        this.$message.error(message);
      }
      this.submitLoading = false;
    },

    async onlySubmit() {
      this.submitLoading = true;
      try {
        const response = await this.$http.post(this.action.config.submitUrl, this.selectedRows.length > 0 ? {
          ...this.getForm(),
          id: this.selectedRows.map(({ id }) => id)
        } : this.getForm());
        if (typeof this.action.config.submitSuccessMessage === 'function') {
          let submitSuccessMessage = this.action.config.submitSuccessMessage;
          submitSuccessMessage = submitSuccessMessage({
            response
          });
          this.$message.success(submitSuccessMessage ?? (this.text + '成功'));
        } else {
          this.$message.success(this.action.config.submitSuccessMessage ?? '操作成功');
        }
        this.openVerifyModal = false;
        this.closeDrawer();
        this.$emit('change');
      } catch ({ message }) {
        this.openVerifyModal = false;
        let type = message.slice(0, 6);
        if (type == 'type=1') {
          this.modalValue = message.slice(7);
          this.openModal = true;
        } else {
          this.$message.error(message);
        }

      }
      this.openVerifyModal = false;
      this.submitLoading = false;
    },

    async modalSubmit() {
      this.submitLoading = true;
      try {
        const response = await this.$http.post(this.action.config.submitUrl, this.selectedRows.length > 0 ? {
          ...this.getForm(),
          id: this.selectedRows.map(({ id }) => id)
        } : this.getForm());
        if (typeof this.action.config.submitSuccessMessage === 'function') {
          let submitSuccessMessage = this.action.config.submitSuccessMessage;
          submitSuccessMessage = submitSuccessMessage({
            response
          });
          this.$message.success(submitSuccessMessage ?? (this.text + '成功'));
        } else {
          this.$message.success(this.action.config.submitSuccessMessage ?? '操作成功');
        }
        this.openWarnModal = false;
        this.closeDrawer();
        this.$emit('change');
      } catch ({ message }) {
        let type = message.slice(0, 6);
        if (type == 'type=1') {
          this.modalValue = message.slice(7);
          this.openModal = true;
        } else {
          this.$message.error(message);
        }

      }
      this.openWarnModal = false;
      this.submitLoading = false;
    },

    closeWarn() {
      this.openWarnModal = false;
    },

    getPlaceholder(control) {
      return (['date', 'datetime', 'select', 'mSelect', 'dateRange'].includes(control.type) ? '选择' : control.type === 'file' ? '上传' : '输入') + control.label;
    },

    getTreeData(control) {
      if (typeof control.config.options === 'function') {
        return control.config.options({
          initData: this.initData
        });
      }
      return control.config.options;
    },
    getHideData(data, control) {
      if (control.config && typeof control.config.hidden === 'function') {
        return control.config.hidden({
          selectedRows: this.selectedRows,
          form: this.form,
          initData: this.initData,
          institutionsName: this.isSpecialInstitutions
        });
      }
      return false
    },
    getCompactData(data, control) {
      let updatedOptions = []
      if (typeof control.config.options === 'string') {
        updatedOptions = (this.initData[control.config.options] ?? []).map(item => ({
          text: item[control.config.textKey ?? 'name'],
          value: item[control.config.valueKey ?? 'id']
        }))
      }
      return updatedOptions
    },
    getStepData(data, control) {
      let updatedOptions = []
      if (typeof control.config.options === 'string') {
        updatedOptions = (this.initData[control.config.options] ?? []).map(item => ({
          content: item[control.config.textKey ?? 'content'],
          id: item[control.config.valueKey ?? 'id']
        }))
        // 将商机流失移动到最后一个位置
        const lostIndex = updatedOptions.findIndex(item => item.content === '商机流失');
        if (lostIndex !== -1) {
          const lostItem = updatedOptions.splice(lostIndex, 1)[0];
          updatedOptions.push(lostItem);
        }
        // 将商机成交移动到倒数第二个位置
        const dealIndex = updatedOptions.findIndex(item => item.content === '商机成交');
        if (dealIndex !== -1) {
          const dealItem = updatedOptions.splice(dealIndex, 1)[0];
          updatedOptions.splice(updatedOptions.length - 1, 0, dealItem);
        }
      } else {
        updatedOptions = control.config.options
      }
      return updatedOptions
    },
    getRadioData(data, control) {
      let source = this.initData?.source?.[control.key]
      //var list = toRaw(source)
      control.config.options.forEach(option => {
        if (typeof option.noshow === 'function') {
          option.disabled = option.noshow({ source });
        }
      });
      return control.config.options
    },
    getMSelectData(data, control) {
      if (control.disabled) {
        let renderData = data[control.config.options];
        let haveData = data.source[control.key];
        renderData.map((item) => {
          item.disabled = haveData.indexOf(item.id) > -1;
        })
        return renderData
      }
      else if (control.dealData) {
        let options = typeof control.config.options === 'string' ? (this.initData[control.config.options] ?? []).map(item => ({
          name: item[control.config.textKey ?? 'name'],
          id: item[control.config.valueKey ?? 'id'],
          disabled: typeof control.config.disabledSelectValue === 'function' ? control.config.disabledSelectValue({
            value: item[control.config.textKey ?? 'name'],
            id: item[control.config.valueKey ?? 'id'],
            source: this.initData?.source,
            institutionsName: this.isSpecialInstitutions
          }) : false
        })) : control.config.options
        return options
      }
      else {
        return data[control.config.options]
      }

    },
    handleChange(value) {
      console.log(`${value}`);
    },

    // isDisabled(value, disabledValue) {
    //   console.log(value, disabledValue, 'pppppppppppppppp');
    //   if (value && value == disabledValue) {
    //     return true
    //   }
    // },

    handleOk() {
      this.openModal = false;
    },

    getSouSelect(value, control, options) {
      let that = this;
      let obj = options.find((item) => item.id === value);
      this.controls.map((item) => {
        if (item.getData) {
          if (item.key == 'handlingCountry') {
            this.form[item.key] = obj.handlingCountry;
          } else if (item.key == 'city') {
            that.form[item.key] = obj.city
          }
        };
        if (item.getAddress) {
          if (item.key == 'address') {
            this.form[item.key] = obj.address;
          } else if (item.key == 'position') {
            that.form[item.key] = obj.city
          }
        }
      })
    },

    filterSouOption(input, option) {
      return option.text.toLowerCase().indexOf(input.trim().toLowerCase()) >= 0;
    },


    getForm() {
      return this.action.config.controls.reduce((prev, control) => {
        if (control.type === 'numberRange') {
          prev[control.start] = this.form[control.start];
          prev[control.end] = this.form[control.end];
        }
        const value = this.form[control.key];
        if (value == null) {
          return prev;
        }
        if (control.type === 'date') {
          if (control.showNow) {
            prev[control.key] = value.format('YYYY-MM-DD HH:mm:ss');
          } else {
            prev[control.key] = value.format('YYYY-MM-DD') + (control.options && control.options.endOfDay ? ' 23:59:59' : ' 00:00:00');
          }
        } else if (control.type === 'datetime') {
          prev[control.key] = value.format('YYYY-MM-DD HH:mm:ss');
        } else if (control.type === 'dateRange') {
          prev[control.config.startKey ?? 'start' + capitalize(control.key)] = value[0]?.format('YYYY-MM-DD HH:mm:ss');
          prev[control.config.endKey ?? 'end' + capitalize(control.key)] = value[1]?.format('YYYY-MM-DD HH:mm:ss')
        } else if (control.type === 'compact') {
          prev[control.key] = value;
          prev[control.config.key] = this.form[control.config.key];
        } else if (control.type === 'remoteSelect') {
          prev[control.searchConfig.formkey] = this.form[control.searchConfig.formkey];
        } else {
          if (control.config && control.config.showkey) {
            prev[control.config.showkey] = this.initData?.source?.[control.config.showkey]
          } else {
            prev[control.key] = value;
          }
        }
        return prev;
      }, {});
    },

    radioChange(e) {
      this.action.config.controls.map(control => {
        if (control.isAssociate && typeof control.config.hidden === 'function') {
          return control.config.hidden({
            selectedRows: this.selectedRows,
            form: this.form,
            initData: this.initData,
          });
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}

.formCon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  .textareaCon {
    width: 100% !important;
  }

  .ant-form-item {
    width: 48%;
    margin: 10px 5px;
  }
}

.m-formCon {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  .textareaCon {
    width: 100% !important;
  }

  .ant-form-item {
    width: 46%;
    margin: 10px 5px;
  }
}

.am-content {
  padding: 20px 0;
}

.fa-info {
  margin-bottom: 30px;
}

.label-style {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ls-required {
  color: red;
  margin-right: 4px;
}

.ls-icon {
  margin-left: 4px;
  font-size: 14px;
  color: #999999;
}

:deep(.ant-input-number-input[disabled]) {
  color: rgba(0, 0, 0, 0.25)
}

:deep(.sortInputNumber.ant-input-number .ant-input-number-input) {
  text-align: center;
}

.sortInputNumber {
  span {
    cursor: pointer;
  }
}

.btn_submit {
  width: 100% !important;
}

//-------------------
.stepCon {
  .defaultcol {
    padding: 10px;
    padding-left: 20px;
    background-color: #f2f2f2;
    text-align: center;
    border-radius: 5px;
    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 25% 50%, 0% 0%);
    display: block;
    cursor: pointer;
    color: #b6b0b0;
    font-size: xx-small;
    margin-bottom: 10px;
  }

  .firstFinshStep {
    padding-left: 10px;

    clip-path: polygon(75% 0%, 100% 50%, 75% 100%, 0% 100%, 0% 0%);
  }

  .first {
    background-color: #0079fe;
    color: #fff;
  }

  .last {
    clip-path: polygon(100% 0, 100% 50%, 100% 99%, 0% 100%, 25% 50%, 0% 0%);
  }

  .lost {
    padding: 10px 0;
    -webkit-clip-path: none;
    clip-path: none;
    background-color: red;
    border-radius: 5px;
    cursor: pointer;
    color: #fff;
    opacity: 0.3;
    text-align: center;
  }

  .stepblue {
    background-color: #0079fe;
    color: #fff;
  }

  .stepred {
    opacity: 1;
  }


}

.ml10 {
  margin-left: 10px;
  padding: 10px !important;
}

.ic-title {
  font-size: 16px;
  font-weight: bold;
}

.colorRadio :deep(.ant-radio-wrapper span.ant-radio+*) {
  padding-inline-start: inherit;
  padding-inline-end: inherit;
}

.colorRadio :deep(.ant-radio-wrapper .ant-radio-inner) {
  border-radius: 2px !important;
}

.colorRadio :deep(.ant-radio-wrapper .ant-radio-inner::after) {
  content: '';
  width: 8px;
  height: 5px;
  border: 2px solid rgb(255, 255, 255);
  border-top: transparent;
  border-right: transparent;
  text-align: center;
  display: block;
  position: absolute;
  top: 12px;
  left: 12px;
  vertical-align: middle;
  transform: rotate(-45deg);
  border-radius: 0px;
  background: none;
  z-index: 999;
}

.color-dot {
  position: absolute;
  top: 3px;
  left: 0px;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-right: 5px;
}

.pc-re-code {
  color: #1677ff;
  display: inline-block;
  width: 140px;
  font-size: 12px;
  border: 1px solid #1677ff;
  text-align: center;
  margin-left: 12px;
  height: 30px;
  line-height: 28px;
  border-radius: 8px;
  cursor: pointer;
  min-width: 66px;
}

.pc-re-code-num {
  @extend .pc-re-code;
  font-size: 14px;
  color: #999999;
  border: 1px solid #999999;
}
</style>
